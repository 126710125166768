<script lang="ts" setup>
const { listJobCampaignOnce } = useJobCampaign()

const props = defineProps({
  lazy: {
    type: String as PropType<'CLIENT_ONLY' | 'TRUE' | 'FALSE'>,
    default: 'CLIENT_ONLY'
  }
})

const listCampaignResult = await listJobCampaignOnce({ page: 1, page_size: 20, status: 'ACTIVE' })
</script>
<template>
  <template v-if="listCampaignResult">
    <JobListCol
      v-for="campaign in listCampaignResult.data"
      :key="campaign.id_slug"
      :see-more="true"
      :title="campaign.title"
      :logo="{
        url: toCompanyIconUrl(campaign.logo) as string,
        width: campaign.logo_width as number,
        height: campaign.logo_height as number
      }"
      :tags="campaign.tags"
      :heading-to="`/job-campaigns/${campaign.id_slug}---${toTitleSlug(campaign.title)}`"
      :query="{
        status: 'PUBLISHED',
        page: 1,
        page_size: 3,
        search_query: campaign.query ? campaign.query : undefined,
        job_campaign__id_slugs: campaign.query ? undefined : [campaign.id_slug]
      }"
      :lazy="props.lazy"
    >
    </JobListCol>
  </template>
</template>
