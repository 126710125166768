<script setup lang="ts">
const { t } = useI18n()
const toLocalePath = useLocalePath()
const viewport = useViewport()
const isMobile = computed(() => viewport.isLessThan('md'))
const { listJobsReactive } = useJob()

const props = defineProps({
  // Search jobs
  query: {
    type: Object as PropType<Parameters<typeof listJobsReactive>[0]>,
    default: () => ({
      status: 'PUBLISHED',
      page: 1,
      page_size: 10,
      order_by: '-posting_date'
    })
  },
  title: {
    type: String,
    default: ''
  },
  logo: {
    type: Object as PropType<{
      url: string
      width: number
      height: number
    }>,
    default: () => ({
      url: '',
      width: 0,
      height: 0
    })
  },
  tags: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  seeMore: {
    type: Boolean,
    default: false
  },
  headingTo: {
    type: String as PropType<string>,
    default: ''
  },
  lazy: {
    type: String as PropType<'CLIENT_ONLY' | 'TRUE' | 'FALSE'>,
    default: 'CLIENT_ONLY'
  },
  excludeJobIdSlug: {
    type: String as PropType<string | number>,
    default: ''
  }
})

const lazyLoading = computed(() => {
  if (props.lazy === 'CLIENT_ONLY') {
    return process.client
  }
  if (props.lazy === 'TRUE') {
    return true
  }
  if (props.lazy === 'FALSE') {
    return false
  }
  return process.client
})

const jobResult = await listJobsReactive(props.query, { lazy: lazyLoading.value })
if (!jobResult) {
  throw createError({
    statusCode: 500,
    message: 'Failed to fetch job data'
  })
}

const { data: jobData, pending: jobDataLoading } = jobResult

const emit = defineEmits(['update:loading', 'update:page'])
watch(
  () => jobDataLoading.value,
  (value) => {
    emit('update:loading', value)
  }
)

const jobs = computed(() => {
  if (!jobData.value) {
    return []
  }
  const jobs = jobData.value.data
    .map((job) => NAPIToTJob(t, job))
    .filter((job) => {
      return job.idSlug !== props.excludeJobIdSlug
    })

  return jobs
})
</script>
<template>
  <div class="flex flex-col">
    <div class="flex items-center justify-between">
      <div class="flex flex-row items-center gap-[0.62rem]">
        <JobCampaignCardLogo :logo="props.logo" :title="props.title" />
        <div class="text-h4 font-medium">
          {{ props.title }}
        </div>
      </div>
      <ButtonBlackButton
        v-if="seeMore"
        :text="$t('common.list.seeMore')"
        variant="text"
        size="small"
        tabindex="-1"
        :to="toLocalePath(headingTo)"
        mode="navigation"
      >
        <template #left-icon>
          <IconsFigmaArrowForwardOutlined />
        </template>
      </ButtonBlackButton>
    </div>
    <div class="mt-[1.25rem] flex flex-wrap gap-[0.62rem]">
      <div
        v-for="tag in tags"
        :key="tag"
        class="w-fit rounded-tag border-[1px] border-secondary-2 px-[0.62rem] py-[0.31rem] text-t6 font-light text-secondary-2"
      >
        {{ tag }}
      </div>
    </div>
    <div class="mt-[1.88rem] flex flex-col gap-[1.25rem] lg:grid lg:grid-cols-3 lg:gap-[1.25rem]">
      <JobCard
        v-for="state in jobs"
        :key="state.idSlug"
        :variant="isMobile ? 'normal' : 'col'"
        :job="state"
      >
        <template v-if="state.logo && state.logo.url && state.company" #company-logo>
          <JobCardLogo :logo="state.logo" :company="state.company" :clickable="false" />
        </template>
      </JobCard>
    </div>
  </div>
</template>
