<template>
  <div
    class="group grid h-auto cursor-pointer overflow-hidden rounded-[0.63rem]"
    @click="
      navigateTo(
        'https://www.techjobasia.com/job-campaigns/n4uwjwFoT9m25Z3Su32Asg---Cyberport-Career-Fair-2024',
        {
          open: {
            target: '_blank'
          }
        }
      )
    "
  >
    <div class="col-start-1 row-start-1 h-0 min-h-full w-full overflow-hidden rounded-[0.63rem]">
      <NuxtImg
        :preload="false"
        src="/images/banner-ccf-side.png"
        class="h-full w-full"
        alt="Cyberport Career Fair 2024"
      />
    </div>
    <div class="col-start-1 row-start-1 flex flex-col p-[1.25rem]">
      <div class="text-h4 font-bold text-white">Cyberport Career Fair 2024</div>
      <div class="h-[1.25rem] border-b border-b-white"></div>
      <div class="flex flex-row items-center justify-between text-white">
        <div
          class="py-[0.62rem] text-[0.875rem] text-t6 font-medium leading-[1.125rem] text-white group-hover:underline"
        >
          Explore more tech jobs
        </div>
        <IconsFigmaArrowForwardOutlined class="h-[1.125rem] w-[1.125rem] shrink-0" />
      </div>
    </div>
  </div>
</template>
